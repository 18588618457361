// Guard against malicious code redefining `undefined`:
// eslint-disable-next-line no-shadow-restricted-names
(function guardAndRedirect(undefined) {
    const UNSUPPORTED_BROWSER_VAR = 'ENERGYSAVVY_BROWSER_IS_UNSUPPORTED';
    window[UNSUPPORTED_BROWSER_VAR] = undefined;

    // Each feature is preceded by a list of supporting browsers
    function browserSupportsRequiredFeatures() {
        // Edge; IE > 8; FF; Safari > 3; Chrome; Opera > 6
        const supportsAddEventListener = 'addEventListener' in window;
        // Edge; IE > 7; FF > 3.4; Safari > 3; Chrome; Opera > 10.4
        const supportsJSON = 'JSON' in window && 'parse' in window.JSON;
        // Edge; IE > 9; FF > 22; Safari > 6.0; Chrome; Opera > 14
        const supportsCancelAnimationFrame = 'cancelAnimationFrame' in window;

        return (
            supportsAddEventListener &&
            supportsJSON &&
            supportsCancelAnimationFrame
        );
    }

    try {
        window[UNSUPPORTED_BROWSER_VAR] = !browserSupportsRequiredFeatures();
    } catch (e) {
        window[UNSUPPORTED_BROWSER_VAR] = null;
    } finally {
        if (window[UNSUPPORTED_BROWSER_VAR] === true) {
            // Use `.replace()` instead of `.assign()` to avoid the user
            // hitting the back button and landing on our app again.
            // (The back button will send them to the referring page instead.)
            document.location.replace('/unsupported-browser/');
        }
    }
})();
